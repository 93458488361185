/**
 * @generated SignedSource<<e2732fb81e870a1cabea1884859a7a15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedRespRelatedItem_item$data = {
  readonly firstPhotoWebPath: string | null;
  readonly isFurniture: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"FavoritesItemProvider_item" | "FavoritesItem_item" | "ItemTile_item">;
  readonly " $fragmentType": "SharedRespRelatedItem_item";
};
export type SharedRespRelatedItem_item$key = {
  readonly " $data"?: SharedRespRelatedItem_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedRespRelatedItem_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isTrade"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "priceBookName"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedRespRelatedItem_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFurniture",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "size",
          "value": "thumb"
        }
      ],
      "kind": "ScalarField",
      "name": "firstPhotoWebPath",
      "storageKey": "firstPhotoWebPath(size:\"thumb\")"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isTrade",
          "variableName": "isTrade"
        },
        {
          "kind": "Variable",
          "name": "priceBookName",
          "variableName": "priceBookName"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ItemTile_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FavoritesItem_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FavoritesItemProvider_item"
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "b14e159064b51277f3c2031d8298054f";

export default node;
